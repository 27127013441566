/* eslint-disable @ngrx/no-dispatch-in-effects */
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FinancialInstitutionService } from '@api/api/financialInstitution.service';
import { UserDeviceManagementService } from '@api/api/userDeviceManagement.service';
import { UpdateDeviceResponse } from '@api/index';
import { AttachDeviceResponse } from '@api/model/attachDeviceResponse';
import { NOTICE_TYPE } from '@core/constants';
import { ERROR_TYPE, OIDC_CODE } from '@core/constants/error-code';
import { PBBEvents } from '@core/constants/events';
import { PBBHTTPHeaders } from '@core/constants/http-headers';
import { fullScreenModalGreyConfig } from '@core/constants/modal.config';
import { REDIRECT_QUERY_PARAMS_KEYS } from '@core/constants/query-params';
import { SNAME } from '@core/constants/storage';
import { Languages } from '@core/services/language.service';
import { LoggerService } from '@core/services/logger.service';
import { NoticeService } from '@core/services/notice.service';
import { WindowService } from '@core/services/window.service';
import * as Utils from '@core/utils';
import { ErrorPageComponent } from '@error/error-page/error-page.component';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';

import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as CheckoutActions from '@store/checkout/checkout.actions';
import { catchError, exhaustMap, filter, map, of, startWith, switchMap, tap } from 'rxjs';
import { v4 } from 'uuid';
import * as CoreActions from './core.actions';
import { selectDeviceId, selectFinancialInstitution } from './core.selectors';

@Injectable()
export class CoreEffects {
  getFinancialInstitution$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreActions.getFinancialInsitutions),
      exhaustMap(() =>
        this.financialInstituteService.getFIAll().pipe(
          map((res) =>
            CoreActions.getFinancialInsitutionsSuccess({
              financialInstitutions: Utils.sortAlphabetic(res, 'display_name'),
            })
          ),
          catchError((error) => {
            return of(CoreActions.getFinancialInsitutionsFailed({ error }));
          })
        )
      ),
      startWith(
        CoreActions.hydrateCore({
          tempemail: sessionStorage.getItem(SNAME.TEMP_EMAIL) || '',
          OIDCCode: (sessionStorage.getItem(SNAME.OIDCCODE) || '') as OIDC_CODE,
        })
      )
    );
  });

  fatalEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(...[CoreActions.getFinancialInsitutionsFailed, CheckoutActions.getConsentFailed]),
        tap((action) => {
          if (action.error instanceof HttpErrorResponse && action.error.status === 401) {
            if (this.merchantWindowService.consentPayload) {
              this.store.dispatch(
                CheckoutActions.getConsentSuccess({ consent: this.merchantWindowService.consentPayload })
              );
            } else {
              this.store.dispatch(CheckoutActions.getConsentStart());
              this.merchantWindowService.sendToMerchant(PBBEvents.konekLoaded);
            }
            this.logger.log('AUTH', 'TOKEN EXPIRED');
          } else {
            this.merchantWindowService.sendToMerchant(PBBEvents.konekError, true, action.type);
          }
        })
      );
    },
    {
      dispatch: false,
    }
  );

  errorEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CheckoutActions.postConsentGrantFailed),
        tap(() => {
          this.dialog.closeAll(); // closing payment in progress dialog
          this.merchantWindowService.sendToMerchant(PBBEvents.konekWarning, false);
        })
      );
    },
    {
      dispatch: false,
    }
  );

  pbbSetErrorEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(...[CoreActions.setError]),
        tap((action) => {
          if (action.errorType === ERROR_TYPE.OVERLAY) {
            this.dialog.open(ErrorPageComponent, {
              data: { errorCode: action.errorCode },
              ...fullScreenModalGreyConfig,
            });
            return;
          }
          const baseUrl = Utils.isCheckout() ? 'checkout/' : 'user-portal/';
          return this.router.navigate(
            [`${action.errorBaseUrl === undefined ? baseUrl : action.errorBaseUrl}error/${action.errorCode}`],
            { queryParams: { retryUrl: action.retryUrl } }
          );
        })
      );
    },
    {
      dispatch: false,
    }
  );

  setMerchantReferrer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreActions.setMerchantReferrer),
      tap((action) => sessionStorage.setItem(SNAME.MERCHANT, action.merchantReferrer)),
      map((action) =>
        CoreActions.setFooterConfig({
          footerConfig: {
            hasMerchantOpener: !!action.merchantReferrer,
          },
        })
      )
    );
  });

  // SELECT FI
  selectFI$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CoreActions.selectFI),
        filter((payload) => (payload.noredirect ? false : true)),
        map((payload) => {
          let authURL = `${payload?.selectedFI?.oauth_url}`;
          let authParams = new HttpParams({})
            .append(PBBHTTPHeaders.correlationId, sessionStorage.getItem(SNAME.CORRELATIONID) || '')
            .append(PBBHTTPHeaders.requestId, v4())
            .append(REDIRECT_QUERY_PARAMS_KEYS.redirectUrl, `${payload.redirectUrl || this.window.location.pathname}`);

          if (payload.consentId) {
            authParams = authParams.append(REDIRECT_QUERY_PARAMS_KEYS.consentId, payload.consentId);
          }
          if (payload.email) {
            authParams = authParams.append(REDIRECT_QUERY_PARAMS_KEYS.email, encodeURIComponent(payload.email) || '');
          }
          if (payload.stepUp && payload.fi_user_id) {
            authParams = authParams.append(REDIRECT_QUERY_PARAMS_KEYS.stepupRequired, true);
            authParams = authParams.append(REDIRECT_QUERY_PARAMS_KEYS.fiUserId, payload.fi_user_id);
          }
          if (payload.login_hint) {
            authParams = authParams.append(REDIRECT_QUERY_PARAMS_KEYS.loginHint, payload?.login_hint);
          }
          if (payload.nonce) {
            authParams = authParams.append(REDIRECT_QUERY_PARAMS_KEYS.nonce, payload?.nonce);
          }
          if (payload.userProfileRef) {
            authParams = authParams.append(REDIRECT_QUERY_PARAMS_KEYS.userProfileRef, payload.userProfileRef);
          }
          if (payload.rus) {
            authParams = authParams.append(REDIRECT_QUERY_PARAMS_KEYS.rus, true);
          }
          authParams = authParams.append(
            REDIRECT_QUERY_PARAMS_KEYS.locale,
            this.translateService.currentLang || Languages.ENGLISH
          );
          authURL = `${authURL}?${authParams.toString()}`;
          if (payload.rus) {
            this.store.dispatch(
              CoreActions.redirectTOFILogin({
                authURL,
              })
            );
            return authURL;
          }

          let url = (Utils.isCheckout() ? '/checkout' : '/user-portal') + '/auth/fi-selection/fi-redirect';
          if (payload.stepUp) {
            url = `${location.pathname}/fi-redirect`;
          }
          this.router.navigate([url], {
            queryParams: {
              authURL,
              stepUp: payload.stepUp,
            },
          });
          return authURL;
        })
      );
    },
    { dispatch: false }
  );

  selectFIByID$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreActions.selectFIByID),
      switchMap((action) =>
        this.store.select(selectFinancialInstitution(action.selectedFIId)).pipe(
          map((selectedFI) =>
            CoreActions.selectFI({
              selectedFI,
              noredirect: true,
              stepUp: false,
            })
          )
        )
      )
    );
  });

  redirectToFI$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CoreActions.redirectTOFILogin),
        map(({ authURL }) => {
          this.logger.log('AUTH', 'REDIRECTING TO FI ', authURL);
          if (Utils.isCheckout()) {
            this.merchantWindowService.handleHeartBeat(-1);
          }
          this.window.location.replace(authURL);
        })
      );
    },
    {
      dispatch: false,
    }
  );

  setOIDCCode$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CoreActions.setOIDCCode),
        tap((action) => {
          sessionStorage.setItem(SNAME.OIDCCODE, action.OIDCCode || '');

          if (action.OIDCCode === '4105') {
            this.logger.log('AUTH', 'USER NOT FOUND', 'LOCAL STORAGE CLEARED');
            localStorage.clear();
          }
        })
      );
    },
    {
      dispatch: false,
    }
  );

  setTempEmail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CoreActions.setTempEmail),
        tap((action) => {
          sessionStorage.setItem(SNAME.TEMP_EMAIL, action.tempemail);
        })
      );
    },
    {
      dispatch: false,
    }
  );

  // device management
  attachDevice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreActions.attachDevice),
      switchMap((action) => {
        return this.userDeviceManagementService
          .attachDevice(action.userRef, {
            user_agent: this.window.navigator.userAgent,
          })
          .pipe(
            map((resp: AttachDeviceResponse) => {
              if (resp.device_ref) {
                return CoreActions.attachDeviceSuccess({ deviceId: resp.device_ref, userRef: action.userRef });
              } else {
                return CoreActions.attachDeviceFailed({ error: new Error('No device_ref') });
              }
            }),
            catchError((error) => of(CoreActions.attachDeviceFailed({ error })))
          );
      })
    );
  });

  attachDeviceSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CoreActions.attachDeviceSuccess),
        map(({ deviceId, userRef }) => {
          this.logger.log('AUTH', 'DEVICE REF ADDED');
          sessionStorage.setItem(Utils.getSessionDeviceIdKeyName(userRef), deviceId);
          Utils.injectProxyHeader(deviceId);
          this.logger.log('WEBAUTHN', 'TRANSMIT PROXY SET');
        })
      );
    },
    { dispatch: false }
  );

  // device update
  updateDevice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreActions.updateDevice),
      switchMap((action) => {
        return this.userDeviceManagementService
          .updateDevices(action.userRef, action.deviceRef, action.deviceDetails)
          .pipe(
            map((res: UpdateDeviceResponse) => {
              return CoreActions.updateDeviceSuccess({ userJWS: res.user_jws, deviceRef: action.deviceRef });
            }),
            catchError((error) => of(CoreActions.updateDeviceFailed({ error })))
          );
      })
    );
  });

  updateDeviceSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CoreActions.updateDeviceSuccess),
        concatLatestFrom(() => this.store.select(selectDeviceId)),
        tap(([action, deviceRef]) => {
          if (action.deviceRef === deviceRef) {
            localStorage.setItem(SNAME.USER_JWS, action.userJWS);
          }
        })
      );
    },
    {
      dispatch: false,
    }
  );

  showSnackBar$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CoreActions.showNotice),
        map(({ notice, error }) => {
          if (notice) {
            this.ns.clearNotices();
            this.ns.addNotice({
              type: error ? NOTICE_TYPE.ERROR : NOTICE_TYPE.INFO,
              title: `COMMON.${notice}_${error ? 'ERROR' : 'SUCCESS'}`,
              dismissable: true,
              // duration: this.duration,
            });
          }
        })
      );
    },
    {
      dispatch: false,
    }
  );
  constructor(
    private actions$: Actions,
    private readonly store: Store,
    private financialInstituteService: FinancialInstitutionService,
    private readonly router: Router,
    public merchantWindowService: WindowService,
    public dialog: MatDialog,
    public window: Window,
    private readonly logger: LoggerService,
    private userDeviceManagementService: UserDeviceManagementService,
    private readonly translateService: TranslateService,
    private ns: NoticeService
  ) {}
}
